
import {defineComponent, ref} from "vue";
import {setCurrentPageBreadcrumbs} from "@/core/helpers/breadcrumb";
import ApiService from "@/core/services/ApiService";
import {Api} from "@/store/enums/StoreEnums";
import {dateFormatWithTime} from "@/core/filters/filters";
import Swal from "sweetalert2";


export default defineComponent({
  name: "EmployeeDocuments",
  setup() {
    return {
      dateFormatWithTime
    }
  },
  data() {
    return {
      loading: false,
      documents: {},
      empDocuments: {},
      employeeId: this.$route.params.id_emp,
      formData: ref({
        template_ids: [],
        employee_id: this.$route.params.id_emp,
      })
    }
  },
  created() {
    this.loadData();
  },
  mounted() {
    setCurrentPageBreadcrumbs("Zaměstnanci", ["Detail", "Dokumenty"]);
    this.getEmployeeDocs();

  },
  methods:
    {
      submit() {
        this.loading = true
        ApiService.postAsFromData(Api.GENERATE_DOCUMENT, this.formData).then(() => {
          this.getEmployeeDocs();
          Swal.fire({
            text: "Dokumenty jsou připraveny",
            icon: "success",
            buttonsStyling: false,
            confirmButtonText: "Ok!",
            customClass: {
              confirmButton: "btn fw-bold btn-light-primary",
            },
          }).then(() => {
            let div = document.querySelector('#documnets_rdy');
            if (div) {
              div.scrollIntoView()
            }
            this.loading = false
          });
        }).catch((reason) => {
          console.error(reason);
          Swal.fire({
            text: "Jejda :-(",
            icon: "error",
            buttonsStyling: false,
            confirmButtonText: "Ok!",
            customClass: {
              confirmButton: "btn fw-bold btn-light-primary",
            }
          }).then(() => {
            this.loading = false
          });
        });
      },
      loadData() {
        ApiService.get(Api.ALL_DOCUMENT).then(({data}) => {
          this.documents = data;
        }).catch(({response}) => {
          console.error(response.data.errors);
        });
      },
      getEmployeeDocs() {
        ApiService.get(Api.GET_EMPLOYEE_DOCUMENTS, {employee_id: this.employeeId})
          .then(response => {
            console.log(response.data);
            this.empDocuments = response.data;
          })
      },
      downloadFile(data, format, name) {
        let fileLink = document.createElement('a');
        if (format == "xml") {
          fileLink.href = 'data:application/xml;base64,' + data;
        } else {
          fileLink.href = 'data:application/octet-stream;base64,' + data;
        }
        fileLink.download = name + "." + format;
        document.body.appendChild(fileLink);
        fileLink.click();
        fileLink.remove()
      },
      generateAll() {
        this.loading = true
        for (let key in this.documents) {
          if (this.documents[key].id) {
            this.formData.template_ids.push(this.documents[key].id as never);
          }
        }
        this.submit();
      }
    }
  ,
})
;
